import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Mainvisual from "../assets/img/mv.png"
import Card from "../components/card"

export default function IndexPage(getData) {
  // Markdown
  let cardData = JSON.parse(JSON.stringify(getData.data.allMarkdownRemark.edges))

  // Recommend
  getData.data.allRecommendJson.edges.map(item => (
    cardData.push({
      node: {
        id: item.node.id,
        fields: item.node.fields,
        frontmatter: {
          title: item.node.name,
          last_modified: item.node.last_modified,
          date: item.node.date,
          thumbnail: null
        }
      }
    })
  ))

  // Timeline
  cardData.push({
    node: {
      id: `timeline`,
      fields: {
        dir: `timeline`,
        slug: `/timeline/`
      },
      frontmatter: {
        title: `書体年表`,
        last_modified: `2020/07/12`,
        date: `2013/02/09`,
        thumbnail: null
      }
    }
  })

  cardData.sort((a, b) => {
    if(a.node.frontmatter.last_modified > b.node.frontmatter.last_modified) return -1
    if(a.node.frontmatter.last_modified < b.node.frontmatter.last_modified) return 1
    return 0
  })

  return (
    <Layout>
      <SEO />
      <main className="w-full pt-8 sm:pt-32 pb-8">
        <div className="text-center">
          <h1 className="font-display text-xl sm:text-2xl">文字書体と　その組み方を　知る</h1>
          <div className="mt-4 sm:mt-8 mb-8 sm:mb-16 mx-2">
            <img className="mx-auto" src={Mainvisual} alt="文字書体と　その組み方を　知る" />
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 md:gap-4 mt-4">
          {cardData.map((item, id) => (
            <Card key={id} type="top" data={item.node} />
          ))}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {status: {eq: "publish"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            last_modified(formatString: "YYYY/MM/DD")
            date(formatString: "YYYY/MM/DD")
            thumbnail
          }
          fields {
            dir
            slug
          }
        }
      }
    }
    allRecommendJson {
      edges {
        node {
          id
          type
          name
          last_modified(formatString: "YYYY/MM/DD")
          date(formatString: "YYYY/MM/DD")
          fields {
            dir
            slug
          }
          data {
            title
            description
            url
            filename
          }
        }
      }
    }
  }
`
